.footer {
  margin-top: 10vh;
  /*padding: 1vw 3vh 1vw 1vh;*/
  padding: 0;
}

.copyright {
  margin-left: 2vw;
}

.tall-footer {
  padding: 1vw 3vh 1vw 1vh;
}

.links {
  margin-right: 2vw;
  text-align: right;
}