.pillar {
    vertical-align: top;
    margin: 1px;
    padding: 1px;
}

.pillar.col {
    padding: 0
}

.pillar .binomial {
    font-weight: bold;
    font-size: large;
}

.pillar-name {
    font-size: larger;
    font-weight: bold;
}
