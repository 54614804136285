.stems-strength-pie-chart {
    font: 12px sans-serif;
    text-anchor: middle;
}

.stems-strength-pie-chart .arc path, .stems-strength-pie-chart circle {
    stroke-width: 0.12em;
    stroke: #fff;
}

.stems-strength-pie-chart .text-background {
    opacity: 0.5;
}

.stems-strength-pie-chart .stem-strength-slice text {
    text-anchor: middle;
    font-weight: bold;
}

.stems-strength-pie-chart .day-master {
    opacity: 0.9;
    font-weight: bold;
    font-size: large;
}