ul .pillars {
    padding-left: 0;
}

.pillars {
    text-align: center;
}

#luck-pillars .pillars {
    overflow-x: scroll;
    overflow-y: hidden;
    list-style-type: none;
}

.pillars .card {
    /*width: 9em;*/
    /*margin-right: 0.5em;*/
    /*display: block;*/
}

.pillars li {
    /*padding: 0 2px;*/
    /*width: 100px;*/
}
