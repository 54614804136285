.privacy-policy-title {
    text-align: center;
    margin-top: 20px;
    margin-bottom: 20px;
}

.privacy-policy-title h1 {
    font-size: 2.5em;
    font-weight: bold;
}

.privacy-policy-heading {
    margin-top: 20px;
    margin-bottom: 10px;
}

.privacy-policy-heading h2 {
    font-size: 1.5em;
    font-weight: bold;
    color: #333;
}

p {
    font-size: 1em;
    color: #666;
    line-height: 1.5;
    margin-bottom: 20px;
}
