.email-with-logo {
    display: flex;
    align-items: center;
    font-family: 'Arial', sans-serif; /* Use a common font family */
    padding: 1vw;
}

.fas.fa-envelope {
    font-size: 24px; /* Adjust the size as needed */
    margin-right: 10px; /* Space between icon and email */
    color: #333; /* Adjust the color as needed */
}

.email {
    font-size: 16px; /* Adjust the font size as needed */
    color: #333; /* Adjust the color as needed */
}
